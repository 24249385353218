import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoginForm from "../components/login-form"

const Music = () => (
  <Layout>
    <SEO title="Music" />
    <div className="container">
      <div className="row">
        <div className="col s12">
          <h1>Music Publishing</h1>
        </div>
        <div className="col m3 hide-on-small-only" />
        <LoginForm name="music"/>
        <div className="col m3 hide-on-small-only" />
      </div>
    </div>
  </Layout>
)

export default Music
